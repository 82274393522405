<template>
  <div
    class="image-wrapper"
    :class="{ fill, loaded }"
    :style="{ paddingBottom }"
  >
    <img ref="image" :src="imageSrc" :srcset="imageSrcset" v-bind="$attrs" />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    src: {
      type: String,
      required: true,
    },
    srcset: {
      type: String,
      default: "",
    },
    fill: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      default: 1,
    },
  },
  data: function () {
    return {
      shouldRender: false,
      loaded: false,
    };
  },
  created() {
    this.observer = new IntersectionObserver(this.onEntry.bind(this), {
      rootMargin: "100px 0px",
    });
  },
  mounted() {
    this.observer.observe(this.$el);
  },
  methods: {
    onEntry(entries) {
      const entry = entries[0];
      if (!entry.target || !entry.isIntersecting) {
        return;
      }

      this.shouldRender = true;
      this.observer.disconnect();

      this.$refs["image"].addEventListener("load", () => {
        requestAnimationFrame(() => {
          this.loaded = true;
        });
      });
    },
  },
  computed: {
    imageSrc() {
      return this.shouldRender ? this.src : "";
    },
    imageSrcset() {
      return this.shouldRender ? this.srcset : "";
    },
    paddingBottom() {
      if (this.fill) {
        return 0;
      }

      return `${100 / this.aspectRatio}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-wrapper {
  width: 100%;
  background-color: rgba(#173309, 0.1);
  transition: background-color 300ms lnear;

  &.fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  &:not(.fill) {
    position: relative;
  }

  &.loaded {
    background-color: transparent;

    &::before {
      opacity: 0;
    }

    img {
      opacity: 1;
      transform: none;
    }
  }

  &::before {
    $size: 40px;
    content: "";
    position: absolute;
    top: calc(50% - 2px);
    left: calc(50% - #{$size / 2});
    width: $size;
    height: 4px;
    background: #173309;
    animation: rotate 1s ease infinite;
    transition: opacity 300ms linear;

    @keyframes rotate {
      to {
        transform: rotate(180deg);
      }
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: cover;
    transition: opacity 300ms linear 250ms, transform 300ms ease; // Transform is used by work-preview;
  }
}
</style>
